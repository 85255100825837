import React, { useState, useEffect, useCallback } from "react"
import { Link } from "gatsby"
import Menu from "../Menu"
import UserAvatar from "../Avatar"

const Header = () => {
  const [showHeader, setShowHeader] = useState(true)
  const [lastScrollY, setLastScrollY] = useState(0)

  // Memoize controlHeader to avoid recreating the function on every render
  const controlHeader = useCallback(() => {
    if (window.scrollY > 100) {
      if (window.scrollY > lastScrollY) {
        // Scrolling down
        setShowHeader(false)
      } else {
        // Scrolling up
        setShowHeader(true)
      }
    }
    setLastScrollY(window.scrollY)
  }, [lastScrollY]) // Add lastScrollY as a dependency

  useEffect(() => {
    window.addEventListener("scroll", controlHeader)
    return () => {
      window.removeEventListener("scroll", controlHeader)
    }
  }, [controlHeader]) // Now include controlHeader in the dependency array

  return (
    <header
      className={`sticky left-0 top-0 z-50 w-full backdrop-blur-sm transition-transform duration-300 ${
        showHeader ? "translate-y-0 transform" : "-translate-y-full transform"
      }`}
    >
      <div className="navbar">
        <Link className="logo" to="/">
          ФДВ
        </Link>
        <div className="hidden sm:block">
          <Menu />
        </div>
        <UserAvatar />
      </div>
    </header>
  )
}

export default Header

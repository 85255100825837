export const contentItems = [
  {
    title: 'Видео / Подкасты',
    path: '/video',
    description: 'Наши ролики на YouTube',
  },
  {
    title: 'Статьи',
    path: 'https://dzen.ru/formula1inrussian',
    isExternal: true,
    description: 'Эксклюзив от Белого Я',
  },

  {
    title: 'Семантический разбор',
    path: '/semantic',
    description: 'Золотая полка ФДВ',
  },
  { title: 'Авторы', path: '/authors', description: 'Основной состав ФДВ' },
]

export const formulaItems = [
  {
    title: 'Чемпионат',
    path: '/championship',
    description: 'Сезон 2025',
  },
  { title: 'Новости', path: '/news', description: 'Актуальные новости Ф1' },
  // {
  //   title: 'Гонщики',
  //   path: '/f1/drivers',
  //   description: 'Информация о гонщиках',
  // },
  // {
  //   title: 'Сезоны',
  //   path: '/f1/seasons',
  //   description: 'Все сезоны Ф1',
  // },
  {
    title: 'Fantasy F1',
    path: '/f1/fantasy',
    description: 'Игра по Формуле-1',
  },
  {
    title: 'Викторина',
    path: '/quiz',
    description: 'Проверь себя',
  },
]

export const communityItems = [
  {
    title: 'Форум',
    path: '/forum',
    description: 'Общение на форуме',
  },
  { title: 'Контакты', path: '/contacts', description: 'Связаться с нами' },
]

import React, { useRef, useState, useEffect } from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import {
  Menu,
  Newspaper,
  Play,
  Home,
  X,
  ChevronDown,
  ChevronUp,
} from 'lucide-react'

import { contentItems, formulaItems, communityItems } from '../../data/menu'

const FooterNav = () => {
  const navRef = useRef()
  const menuIconRef = useRef()
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [activeAccordion, setActiveAccordion] = useState(null)

  const mobileMenu = () => {
    const isOpen = !isMenuOpen
    setIsMenuOpen(isOpen)
    navRef.current.classList.toggle('nav-list-mobile', isOpen)

    // Disable or enable body scroll when menu is toggled
    document.body.style.overflow = isOpen ? 'hidden' : 'auto'
  }

  const closeMenu = () => {
    setIsMenuOpen(false)
    navRef.current.classList.remove('nav-list-mobile')

    // Re-enable body scroll when menu is closed
    document.body.style.overflow = 'auto'
  }

  const toggleAccordion = section => {
    setActiveAccordion(activeAccordion === section ? null : section)
  }

  // Clean up on component unmount
  useEffect(() => {
    // When the component unmounts, reset the body overflow
    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [])

  return (
    <div className="footer-nav z-50 flex sm:hidden">
      <Link
        className="flex cursor-pointer flex-col items-center text-xs text-neutral-400"
        to="/"
        activeClassName="linkActive"
        onClick={closeMenu}
      >
        <Home />
        Главная
      </Link>

      <Link
        className="flex cursor-pointer flex-col items-center text-xs text-neutral-400"
        to="/video"
        activeClassName="linkActive"
        onClick={closeMenu}
      >
        <Play />
        Видео
      </Link>

      <a
        href="https://dzen.ru/formula1inrussian"
        className="flex cursor-pointer flex-col items-center text-xs text-neutral-400"
        target="_blank"
        rel="noopener noreferrer"
        onClick={closeMenu}
      >
        <Newspaper />
        Статьи
      </a>

      <div
        className="flex cursor-pointer flex-col items-center text-xs text-neutral-400"
        onClick={mobileMenu}
        onKeyDown={e => e.key === 'Enter' && mobileMenu()}
        ref={menuIconRef}
        role="button"
        tabIndex={0}
        aria-label="Toggle menu"
      >
        {isMenuOpen ? <X /> : <Menu />}
        Меню
      </div>

      <ul className="nav-list z-50 backdrop-blur-sm" ref={navRef}>
        <li>
          <StaticImage
            className="size-32 rounded-lg"
            src="../../images/logo.png"
            alt="Формула для всех"
            placeholder="blurred"
          />
        </li>

        {/* Content Items Accordion */}
        <li>
          <div
            className="flex cursor-pointer items-center gap-4"
            onClick={() => toggleAccordion('content')}
            onKeyDown={e => e.key === 'Enter' && toggleAccordion('content')}
            role="button"
            tabIndex={0}
            aria-expanded={activeAccordion === 'content'}
            aria-label="Toggle content section"
          >
            Контент
            {activeAccordion === 'content' ? <ChevronUp /> : <ChevronDown />}
          </div>
          <div
            className={`overflow-hidden transition-all duration-300 ease-in-out ${
              activeAccordion === 'content'
                ? 'max-h-screen opacity-100'
                : 'max-h-0 opacity-0'
            }`}
          >
            <ul className="ml-4 mt-2">
              {contentItems.map((item, id) => (
                <li key={id} className="py-1">
                  {item.isExternal ? (
                    <a
                      href={item.path}
                      className="text-base"
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={closeMenu}
                    >
                      {item.title}
                    </a>
                  ) : (
                    <Link
                      to={item.path}
                      className="text-base"
                      onClick={closeMenu}
                    >
                      {item.title}
                    </Link>
                  )}
                </li>
              ))}
            </ul>
          </div>
        </li>

        {/* Formula Items Accordion */}
        <li>
          <div
            className="flex cursor-pointer items-center gap-4"
            onClick={() => toggleAccordion('formula')}
            onKeyDown={e => e.key === 'Enter' && toggleAccordion('formula')}
            role="button"
            tabIndex={0}
            aria-expanded={activeAccordion === 'formula'}
            aria-label="Toggle formula section"
          >
            Формула 1
            {activeAccordion === 'formula' ? <ChevronUp /> : <ChevronDown />}
          </div>
          <div
            className={`overflow-hidden transition-all duration-300 ease-in-out ${
              activeAccordion === 'formula'
                ? 'max-h-screen opacity-100'
                : 'max-h-0 opacity-0'
            }`}
          >
            <ul className="ml-4 mt-2">
              {formulaItems.map((item, id) => (
                <li key={id} className="py-1">
                  <Link
                    to={item.path}
                    className="text-base"
                    onClick={closeMenu}
                  >
                    {item.title}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </li>

        {/* Community Items Accordion */}
        <li>
          <div
            className="flex cursor-pointer items-center gap-4"
            onClick={() => toggleAccordion('community')}
            onKeyDown={e => e.key === 'Enter' && toggleAccordion('community')}
            role="button"
            tabIndex={0}
            aria-expanded={activeAccordion === 'community'}
            aria-label="Toggle community section"
          >
            Сообщество
            {activeAccordion === 'community' ? <ChevronUp /> : <ChevronDown />}
          </div>
          <div
            className={`overflow-hidden transition-all duration-300 ease-in-out ${
              activeAccordion === 'community'
                ? 'max-h-screen opacity-100'
                : 'max-h-0 opacity-0'
            }`}
          >
            <ul className="ml-4 mt-2">
              {communityItems.map((item, id) => (
                <li key={id} className="py-1">
                  <Link
                    to={item.path}
                    className="text-base"
                    onClick={closeMenu}
                  >
                    {item.title}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </li>

        <li>
          <a
            href="https://www.donationalerts.com/r/f1forall"
            target="_blank"
            rel="noopener noreferrer"
            className="text-neutral-200"
            onClick={closeMenu}
          >
            Поддержать
          </a>
        </li>
      </ul>

      {/* Transparent overlay to capture clicks */}
      {isMenuOpen && (
        <div
          className="fixed inset-0 z-10 bg-transparent"
          onClick={closeMenu}
          onKeyDown={e => e.key === 'Enter' && closeMenu()}
          role="button"
          tabIndex={0}
          aria-label="Close menu"
        ></div>
      )}
    </div>
  )
}

export default FooterNav

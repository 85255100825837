exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-authors-js": () => import("./../../../src/pages/authors.js" /* webpackChunkName: "component---src-pages-authors-js" */),
  "component---src-pages-championship-js": () => import("./../../../src/pages/championship.js" /* webpackChunkName: "component---src-pages-championship-js" */),
  "component---src-pages-contacts-js": () => import("./../../../src/pages/contacts.js" /* webpackChunkName: "component---src-pages-contacts-js" */),
  "component---src-pages-f-1-drivers-js": () => import("./../../../src/pages/f1/drivers.js" /* webpackChunkName: "component---src-pages-f-1-drivers-js" */),
  "component---src-pages-f-1-fantasy-js": () => import("./../../../src/pages/f1/fantasy.js" /* webpackChunkName: "component---src-pages-f-1-fantasy-js" */),
  "component---src-pages-f-1-seasons-js": () => import("./../../../src/pages/f1/seasons.js" /* webpackChunkName: "component---src-pages-f-1-seasons-js" */),
  "component---src-pages-failed-js": () => import("./../../../src/pages/failed.js" /* webpackChunkName: "component---src-pages-failed-js" */),
  "component---src-pages-forum-js": () => import("./../../../src/pages/forum.js" /* webpackChunkName: "component---src-pages-forum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/PrivacyPolicy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-quiz-js": () => import("./../../../src/pages/quiz.js" /* webpackChunkName: "component---src-pages-quiz-js" */),
  "component---src-pages-semantic-js": () => import("./../../../src/pages/semantic.js" /* webpackChunkName: "component---src-pages-semantic-js" */),
  "component---src-pages-settings-js": () => import("./../../../src/pages/settings.js" /* webpackChunkName: "component---src-pages-settings-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-user-js": () => import("./../../../src/pages/user.js" /* webpackChunkName: "component---src-pages-user-js" */),
  "component---src-pages-video-js": () => import("./../../../src/pages/video.js" /* webpackChunkName: "component---src-pages-video-js" */),
  "component---src-pages-you-tube-slug-js": () => import("./../../../src/pages/{youTube.slug}.js" /* webpackChunkName: "component---src-pages-you-tube-slug-js" */),
  "component---src-templates-driver-js": () => import("./../../../src/templates/driver.js" /* webpackChunkName: "component---src-templates-driver-js" */),
  "component---src-templates-season-js": () => import("./../../../src/templates/season.js" /* webpackChunkName: "component---src-templates-season-js" */)
}


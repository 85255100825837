import React from "react"

import { Script, useStaticQuery, graphql, Link } from "gatsby"
import { StaticImage, GatsbyImage } from "gatsby-plugin-image"

const scripts = {
  addCopyright: `/scripts/addCopyright.js`,
}

const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      allDataLinksFooterJson {
        nodes {
          link
          alt
          image {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
    }
  `)
  return (
    <footer>
      <div className="footer_container">
        <div className="footer_left">
          <div className="footer-logo">
            <StaticImage
              src="../../images/logo.png"
              alt="Формула для всех"
              placeholder="blurred"
            ></StaticImage>
          </div>
          <div className="copyright">
            © 2018-{new Date().getFullYear()} Формула для всех. All rights
            reserved. All trademarks are the property of their respective owners
          </div>
        </div>
        <div className="footer_right">
          <div>Подписывайтесь на наши ресурсы:</div>
          <div className="social">
            {data.allDataLinksFooterJson.nodes.map((node, id) => {
              return (
                <a
                  href={node.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  key={id}
                >
                  <GatsbyImage
                    image={node.image.childImageSharp.gatsbyImageData}
                    alt={node.alt}
                    className="social-images"
                  />
                </a>
              )
            })}
          </div>
        </div>
      </div>
      <div className="footer_f1">
        This website is not associated in any way with the Formula 1 companies.
        F1, FORMULA ONE, FORMULA 1, FIA FORMULA ONE WORLD CHAMPIONSHIP, GRAND
        PRIX and related marks are trade marks of Formula One Licensing B.V
      </div>
      <Link to="/PrivacyPolicy" className="text-xs">
        Политика конфиденциальности
      </Link>

      <Script src={scripts.addCopyright} />
    </footer>
  )
}

export default Footer

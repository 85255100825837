import React from 'react'
import { UserProvider } from './src/lib/context/user'
import { AnimatePresence } from 'framer-motion'
import Layout from './src/components/layout'

import './src/normalize.css'
import './src/style.css'

export const wrapPageElement = ({ element }) => (
  <Layout>
    <AnimatePresence mode="wait">{element}</AnimatePresence>
  </Layout>
)

export const wrapRootElement = ({ element }) => (
  <UserProvider>
    <AnimatePresence mode="wait">{element}</AnimatePresence>
  </UserProvider>
)

import React, { useState, useEffect } from "react"
import { motion, AnimatePresence } from "framer-motion"
import { CircleArrowUp } from "lucide-react"
import Header from "./Header/header"
import Footer from "./Footer/footer"
import FooterNav from "./FooterNav"

const ScrollToTop = () => {
  const [isVisible, setIsVisible] = useState(false)

  const toggleVisibility = () => {
    if (window.pageYOffset > 500) {
      setIsVisible(true)
    } else {
      setIsVisible(false)
    }
  }

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    })
  }

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility)
    return () => window.removeEventListener("scroll", toggleVisibility)
  }, [])

  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 50 }}
          transition={{ duration: 0.3 }}
          className="fixed bottom-20 right-5 z-40 flex cursor-pointer items-center justify-center shadow-md"
          onClick={scrollToTop}
        >
          <CircleArrowUp size={48} color="orange" />
        </motion.div>
      )}
    </AnimatePresence>
  )
}

const Layout = ({ children }) => {
  return (
    <>
      <Header />

      <div className="global-wrapper">
        <motion.main
          initial={{ opacity: 0, x: -200 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: 200 }}
          transition={{
            type: "spring",
            mass: 0.35,
            stiffness: 75,
            duration: 0.3,
          }}
        >
          {children}
        </motion.main>
        <Footer />
      </div>
      <FooterNav />
      <ScrollToTop />
    </>
  )
}

export default Layout

import React, { memo, useMemo } from "react"
import { Link } from "gatsby"
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
} from "@/components/ui/navigation-menu"
import { contentItems, formulaItems, communityItems } from "../../data/menu"

const Menu = () => {
  const renderMenuItems = useMemo(
    () => items => (
      <ul className="m-0 grid gap-3 p-4 md:w-[400px] lg:w-[500px] lg:grid-cols-[.75fr_1fr]">
        {items.map(item => (
          <li key={item.title}>
            <NavigationMenuLink asChild>
              {item.isExternal ? (
                <a
                  className="flex h-full w-full select-none flex-col justify-start rounded-md bg-gradient-to-b from-muted/50 to-muted p-4 no-underline outline-none transition-all duration-500 hover:bg-neutral-600 focus:shadow-md"
                  href={item.path}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="text-lg font-bold uppercase leading-tight">
                    {item.title}
                  </div>
                  <p className="text-sm text-muted-foreground">
                    {item.description}
                  </p>
                </a>
              ) : (
                <Link
                  className="flex h-full w-full select-none flex-col justify-start rounded-md bg-gradient-to-b from-muted/50 to-muted p-4 no-underline outline-none transition-all duration-500 hover:bg-neutral-600 focus:shadow-md"
                  to={item.path}
                >
                  <div className="text-lg font-bold uppercase leading-tight">
                    {item.title}
                  </div>
                  <p className="text-sm text-muted-foreground">
                    {item.description}
                  </p>
                </Link>
              )}
            </NavigationMenuLink>
          </li>
        ))}
      </ul>
    ),
    [],
  )

  return (
    <NavigationMenu>
      <NavigationMenuList className="m-0 flex gap-2">
        {/* Контент Menu Item */}
        <NavigationMenuItem>
          <NavigationMenuTrigger className="menu font-small bg-neutral-800/70 px-4 py-2 text-base text-white hover:bg-neutral-800 hover:text-neutral-200 focus:outline-none focus:ring-2 focus:ring-orange data-[active]:bg-neutral-800 data-[state=open]:bg-neutral-800">
            Контент
          </NavigationMenuTrigger>
          <NavigationMenuContent className="rounded-md bg-neutral-950 shadow-lg">
            {renderMenuItems(contentItems)}
          </NavigationMenuContent>
        </NavigationMenuItem>

        {/* Формула 1 Menu Item */}
        <NavigationMenuItem>
          <NavigationMenuTrigger className="menu bg-neutral-800/70 px-4 py-2 text-base font-medium text-white hover:bg-neutral-800 hover:text-neutral-200 focus:outline-none focus:ring-2 focus:ring-orange data-[active]:bg-neutral-800 data-[state=open]:bg-neutral-800">
            Формула 1
          </NavigationMenuTrigger>
          <NavigationMenuContent className="rounded-md bg-neutral-950 shadow-lg">
            {renderMenuItems(formulaItems)}
          </NavigationMenuContent>
        </NavigationMenuItem>

        {/* Сообщество Menu Item */}
        <NavigationMenuItem>
          <NavigationMenuTrigger className="menu bg-neutral-800/70 px-4 py-2 text-base font-medium text-white hover:bg-neutral-800 hover:text-neutral-200 focus:outline-none focus:ring-2 focus:ring-orange data-[active]:bg-neutral-800 data-[state=open]:bg-neutral-800">
            Сообщество
          </NavigationMenuTrigger>
          <NavigationMenuContent className="rounded-md bg-neutral-950 shadow-lg">
            {renderMenuItems(communityItems)}
          </NavigationMenuContent>
        </NavigationMenuItem>
      </NavigationMenuList>
    </NavigationMenu>
  )
}

export default memo(Menu)

// src/components/PersistentAvatar.js
import React, { useState, useEffect } from "react"
import { Avatar, AvatarFallback } from "@/components/ui/avatar"

// Global image cache to persist across component instances
const imageCache = {}

const PersistentAvatar = ({ src, alt, username }) => {
  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    if (!src) {
      setIsLoaded(false)
      return
    }

    // If image is already cached as loaded, set isLoaded to true
    if (imageCache[src]) {
      setIsLoaded(true)
      return
    }

    const img = new Image()
    img.src = src

    const handleLoad = () => {
      imageCache[src] = true
      setIsLoaded(true)
    }

    const handleError = () => {
      setIsLoaded(false)
    }

    img.onload = handleLoad
    img.onerror = handleError

    // Cleanup event listeners on unmount
    return () => {
      img.onload = null
      img.onerror = null
    }
  }, [src])

  return (
    <Avatar>
      {isLoaded && src ? (
        <img src={src} alt={alt} className="h-full w-full object-cover" />
      ) : (
        <AvatarFallback className="bg-neutral-800 text-xl uppercase">
          {username ? username.charAt(0) : "?"}
        </AvatarFallback>
      )}
    </Avatar>
  )
}

export default PersistentAvatar

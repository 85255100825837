import React, { useState, useEffect } from 'react'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'
import { AlertCircle, LogIn } from 'lucide-react'
import { useUser } from '../lib/context/user'
import Google from '../images/google.svg'
import Yandex from '../images/yandex.svg'

export default function AuthDialog({ open, onClose }) {
  const { login, register, loginWithGoogle, loginWithYandex } = useUser()
  const [loginEmail, setLoginEmail] = useState('')
  const [loginPassword, setLoginPassword] = useState('')
  const [registerName, setRegisterName] = useState('')
  const [registerUsername, setRegisterUsername] = useState('')
  const [registerEmail, setRegisterEmail] = useState('')
  const [registerPassword, setRegisterPassword] = useState('')
  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isOAuthLoading, setIsOAuthLoading] = useState(false)

  useEffect(() => {
    if (!open) {
      setError('')
      setIsLoading(false)
      setIsOAuthLoading(false)
      setLoginEmail('')
      setLoginPassword('')
      setRegisterName('')
      setRegisterUsername('')
      setRegisterEmail('')
      setRegisterPassword('')
    }
  }, [open])

  const handleLoginWithGoogle = async () => {
    if (isOAuthLoading) return

    setError('')
    setIsOAuthLoading(true)
    try {
      await loginWithGoogle()
    } catch (err) {
      setError('Не удалось войти через Google. Пожалуйста, попробуйте снова.')
      setIsOAuthLoading(false)
      console.error('Google auth error:', err)
    }
  }

  const handleLoginWithYandex = async () => {
    if (isOAuthLoading) return

    setError('')
    setIsOAuthLoading(true)
    try {
      await loginWithYandex()
    } catch (err) {
      setError('Не удалось войти через Яндекс. Пожалуйста, попробуйте снова.')
      setIsOAuthLoading(false)
      console.error('Yandex auth error:', err)
    }
  }

  const handleLogin = async e => {
    e.preventDefault()
    if (!loginEmail || !loginPassword) {
      setError('Пожалуйста, введите и email, и пароль.')
      return
    }

    setIsLoading(true)
    try {
      await login(loginEmail, loginPassword)
      setError('') // Clear error on success
      onClose()
      setIsLoading(false)
    } catch (err) {
      setError(`Не удалось войти: ${err.message}`)
      setIsLoading(false)
    }
  }

  const handleRegister = async e => {
    e.preventDefault()
    if (
      !registerName ||
      !registerUsername ||
      !registerEmail ||
      !registerPassword
    ) {
      setError('Пожалуйста, заполните все поля.')
      return
    }

    setIsLoading(true)
    try {
      await register(
        registerEmail,
        registerPassword,
        registerName,
        registerUsername,
      )
      setError('') // Clear error on success
      onClose()
      setIsLoading(false)
    } catch (err) {
      setError(err.message)
      setIsLoading(false)
    }
  }

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogTrigger asChild>
        <button className="button">
          <LogIn />
        </button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Аутентификация</DialogTitle>
          <DialogDescription>
            Войдите в свою учётную запись или создайте новую.
          </DialogDescription>
        </DialogHeader>
        <Tabs defaultValue="login" className="w-full">
          <TabsList className="grid w-full grid-cols-2">
            <TabsTrigger value="login" disabled={isLoading || isOAuthLoading}>
              Вход
            </TabsTrigger>
            <TabsTrigger
              value="register"
              disabled={isLoading || isOAuthLoading}
            >
              Регистрация
            </TabsTrigger>
          </TabsList>
          <TabsContent value="login">
            <form onSubmit={handleLogin}>
              <div className="grid gap-4 py-4">
                <div className="grid grid-cols-4 items-center gap-4">
                  <Label htmlFor="loginEmail" className="text-right">
                    Email
                  </Label>
                  <Input
                    id="loginEmail"
                    type="email"
                    className="col-span-3"
                    value={loginEmail}
                    onChange={e => setLoginEmail(e.target.value)}
                    placeholder="Введите email"
                    autoComplete="email"
                  />
                </div>
                <div className="grid grid-cols-4 items-center gap-4">
                  <Label htmlFor="loginPassword" className="text-right">
                    Пароль
                  </Label>
                  <Input
                    id="loginPassword"
                    type="password"
                    className="col-span-3"
                    value={loginPassword}
                    onChange={e => setLoginPassword(e.target.value)}
                    placeholder="Введите пароль"
                    autoComplete="current-password"
                  />
                </div>
              </div>
              {error && (
                <div className="mb-4 flex items-center gap-2 text-orange">
                  <AlertCircle className="h-4 w-4" />
                  <p className="text-sm">{error}</p>
                </div>
              )}
              <div className="flex flex-col items-center justify-end gap-4">
                <button
                  type="submit"
                  className={`button mb-4 w-full ${isLoading ? 'animate-pulse' : ''} justify-center`}
                  disabled={isLoading || isOAuthLoading}
                >
                  {isLoading ? 'Загрузка...' : 'Войти'}
                </button>
              </div>
            </form>
            <div className="flex flex-col gap-4">
              <button
                onClick={handleLoginWithGoogle}
                className={`button w-full items-center justify-center ${
                  isOAuthLoading ? 'animate-pulse opacity-70' : ''
                }`}
                disabled={isLoading || isOAuthLoading}
              >
                <img src={Google} alt="Google" className="mr-2 h-4 w-4" />
                {isOAuthLoading ? 'Загрузка...' : 'Войти с помощью Google'}
              </button>
              <button
                onClick={handleLoginWithYandex}
                className={`button w-full items-center justify-center ${
                  isOAuthLoading ? 'animate-pulse opacity-70' : ''
                }`}
                disabled={isLoading || isOAuthLoading}
              >
                <img src={Yandex} alt="Yandex" className="mr-2 h-4 w-4" />
                {isOAuthLoading ? 'Загрузка...' : 'Войти с помощью Yandex'}
              </button>
            </div>
          </TabsContent>
          <TabsContent value="register">
            <form onSubmit={handleRegister}>
              <div className="grid gap-4 py-4">
                <div className="grid grid-cols-4 items-center gap-4">
                  <Label htmlFor="registerName" className="text-right">
                    Имя
                  </Label>
                  <Input
                    id="registerName"
                    type="text"
                    className="col-span-3"
                    value={registerName}
                    onChange={e => setRegisterName(e.target.value)}
                    placeholder="Ваше имя"
                    autoComplete="name"
                  />
                </div>
                <div className="grid grid-cols-4 items-center gap-4">
                  <Label htmlFor="registerUsername" className="text-right">
                    Никнейм
                  </Label>
                  <Input
                    id="registerUsername"
                    type="text"
                    className="col-span-3"
                    value={registerUsername}
                    onChange={e => setRegisterUsername(e.target.value)}
                    placeholder="Ваш никнейм"
                    autoComplete="username"
                  />
                </div>
                <div className="grid grid-cols-4 items-center gap-4">
                  <Label htmlFor="registerEmail" className="text-right">
                    Email
                  </Label>
                  <Input
                    id="registerEmail"
                    type="email"
                    className="col-span-3"
                    value={registerEmail}
                    onChange={e => setRegisterEmail(e.target.value)}
                    placeholder="Введите email"
                    autoComplete="email"
                  />
                </div>
                <div className="grid grid-cols-4 items-center gap-4">
                  <Label htmlFor="registerPassword" className="text-right">
                    Пароль
                  </Label>
                  <Input
                    id="registerPassword"
                    type="password"
                    className="col-span-3"
                    value={registerPassword}
                    onChange={e => setRegisterPassword(e.target.value)}
                    placeholder="Введите пароль"
                    autoComplete="new-password"
                  />
                </div>
              </div>
              {error && (
                <div className="mb-4 flex items-center gap-2 text-orange">
                  <AlertCircle className="h-4 w-4" />
                  <p className="text-sm">{error}</p>
                </div>
              )}
              <div className="flex justify-end">
                <button
                  type="submit"
                  className={`button ${isLoading ? 'animate-pulse' : ''}`}
                  disabled={isLoading}
                >
                  {isLoading ? 'Регистрация...' : 'Зарегистрироваться'}
                </button>
              </div>
            </form>
          </TabsContent>
        </Tabs>
      </DialogContent>
    </Dialog>
  )
}

import React, { useCallback } from "react"
import { navigate } from "gatsby"
import { Settings, LogOut } from "lucide-react"
import { useUser } from "../../lib/context/user"
import { Avatar, AvatarImage, AvatarFallback } from "@/components/ui/avatar"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import PersistentAvatar from "@/components/PersistentAvatar"
import { Button } from "@/components/ui/button"
import LoginDialog from "../LoginDialog"
import { Skeleton } from "@/components/ui/skeleton"

export default function UserAvatar() {
  const { current: profile, logout, loading } = useUser()

  const handleNavigate = useCallback(path => {
    navigate(path)
  }, [])

  return (
    <>
      {loading ? (
        <Skeleton className="h-10 w-10 rounded-full" />
      ) : profile ? (
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button
              variant="outline"
              size="icon"
              className="overflow-hidden rounded-full"
            >
              <PersistentAvatar
                src={profile?.imageUrl}
                alt={profile?.name || "User Avatar"}
                username={profile?.username}
              />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuItem
              onClick={() => handleNavigate(`/user/${profile.username}`)}
              className="flex gap-2"
            >
              <Avatar className="size-10">
                <AvatarImage
                  src={profile.imageUrl || ""}
                  alt={profile.name || "User Avatar"}
                  className="object-cover"
                />
                <AvatarFallback className="bg-neutral-700 text-3xl uppercase">
                  {profile ? profile.username.charAt(0) : "?"}
                </AvatarFallback>
              </Avatar>
              {profile.name}
            </DropdownMenuItem>
            <DropdownMenuItem
              onClick={() => handleNavigate("/settings")}
              className="flex gap-2"
            >
              <Settings size={18} />
              Настройки
            </DropdownMenuItem>
            <DropdownMenuSeparator />
            <DropdownMenuItem onClick={() => logout()} className="flex gap-2">
              <LogOut size={18} />
              Выйти
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      ) : (
        <LoginDialog />
      )}
    </>
  )
}
